/**
 * Created by ashahab on 7/26/2017.
 */
import React, { Component } from "react";
import { connect } from "react-redux";
import dummyImage from '../../../assets/images/dummyPerson.jpg';
import { s3Upload } from '../../activities/uploader/s3-uploader';
import $ from "jquery";
import apiClient from "../../../actions/api-client";
import ProgressIndicator from "../../progress-indicator/progress-indicator";
import { Button, Modal, Alert } from "react-bootstrap";
import setCurrentUser from '../../../actions/login-action';
import TextFieldGroup from "../../forms/text-field-group";
import Validator from 'validator';
import isEmpty from 'lodash/isEmpty';
import { changePassword } from "../teacher-profile/change-password";
import { browserHistory } from 'react-router';
import SelectFieldGroup from "../../forms/select-field-group";
import {
    CognitoUserPool,
    CognitoUserAttribute
} from 'amazon-cognito-identity-js';
import ClevertapReact from "clevertap-react";
import config from '../../../config.js';
import "./custom.css";
import classnames from "classnames";
import betaIcon from '../../../assets/images/beta-icon.png';

class StudentProfile extends Component {

    constructor(props) {
        super(props);
        this.showIndicator = this.showIndicator.bind(this);
        this.hideIndicator = this.hideIndicator.bind(this);
        this.enableInputs = this.enableInputs.bind(this);
        this.disableInputs = this.disableInputs.bind(this);
        this.onChange = this.onChange.bind(this);
        this.onSubmit = this.onSubmit.bind(this);
        this.handleAlertDismiss = this.handleAlertDismiss.bind(this);
        this.refreshCache = this.refreshCache.bind(this);
        this.cancelSubmit = this.cancelSubmit.bind(this);
        this.showDialog = this.showDialog.bind(this);
        this.getChangePasswordDialog = this.getChangePasswordDialog.bind(this);
        this.submitPassword = this.submitPassword.bind(this);
        this.submitEnrollmentCode = this.submitEnrollmentCode.bind(this);
        this.getEnrollmentDialogBox = this.getEnrollmentDialogBox.bind(this);
        this.getSchoolCodeDialog = this.getSchoolCodeDialog.bind(this);
        this.submitSchoolCode = this.submitSchoolCode.bind(this);
        this.getEnrollmentCodes = this.getEnrollmentCodes.bind(this);
        this.onLanguageSelect = this.onLanguageSelect.bind(this);
        this.disabledUserMessage = this.disabledUserMessage.bind(this);
        this.state = {
            userName: '', firstName: '', lastName: '', email: '', showEnrollmentDialogBox: false, showSchoolCodeDiaogBox: false,
            school: '', showChangePasswordDialogBox: false, oldPassword: '', newPassword: '', confirmPassword: '', schoolCode: '', enrollmentCode: '', errors: { schoolCode: '', enrollmentCode: '', oldPassword: '', newPassword: '', confirmPassword: '' },
            alertText: "This is a sample alert", alertStyle: "danger", showAlert: false, allEnrollments: [],
            showDialog: false, dialogTitle: "", dialogMessage: "", languages: config.languages, isLanguage: 'English', langCode: 'en', multiLingualLicense: false,
        };
        this.editMode = false;
    }

    componentDidMount() {
        let userObject = this.props.auth.user;
        this.setState(
            {
                userName: userObject.userName,
                profilePicture: userObject.profilePicture,
                firstName: userObject.firstName,
                lastName: userObject.lastName,
                email: userObject.email,
                school: userObject.schoolName,
                langCode: userObject.prefLanguage ? userObject.prefLanguage : 'en',
                isLanguage: userObject.prefLanguage ? this.state.languages.filter(l => l.id === userObject.prefLanguage)[0].name : "English",
                multiLingualLicense: userObject.multiLingualLicense,
            });
        this.getEnrollmentCodes();
        this.disabledUserMessage();
    }

    disabledUserMessage() {
        const user = this.props.auth.user.userName;
        const message = `The trial period or subscription has expired for user, ${user}. Please contact support@learnerpal.com`;
        if (this.props.auth.user.userStatus == 'Disable') {
            this.setState({
                showAlert: true,
                alertText: message,
                unsetHeight: true
            })
        }
    }


    getEnrollmentCodes() {
        let that = this;
        let params = {
            userName: that.props.auth.user.userName
        };
        let pathTemplate = '/user/periods/{userName}';
        let method = 'GET';
        let additionalParams = {};
        let body = {};
        apiClient.invokeApi(params, pathTemplate, method, additionalParams, body, "2").then(function (result) {
            let enrollCodes = result.data;
            let allEnrollments = []
            enrollCodes.forEach(c => {
                allEnrollments.push(<div style={{ padding: '5px', borderBottom: '1px solid #ccc' }}>{c}</div>);
            });
            that.setState({ 'allEnrollments': allEnrollments });
        }).catch(function (result) {

        });

    }

    onLanguageSelect(e) {
        const code = e.target.value
        const lang = e.target.selectedOptions[0].text
        this.setState({ isLanguage: lang, langCode: code });
    }

    showIndicator() {
        this.setState({
            ...this.state,
            loading: true
        });
    }

    hideIndicator() {
        this.setState({
            ...this.state,
            loading: false
        });
    }

    enableInputs() {
        $('.onEditHide,.defaultKeywords').hide();
        $('.showAble').show();
        $('#submitButton').removeClass('removeDisplay');
        $('#cancelButton').removeClass('removeDisplay');
        $('#editButton1').addClass('removeDisplay');
        $('#editButton2').addClass('removeDisplay');
        $('#editButton3').addClass('removeDisplay');
        $('#editButton6').addClass('removeDisplay');

        this.editMode = true;
    }
    cancelSubmit() {
        $('#submitButton').addClass('removeDisplay');
        $('#cancelButton').addClass('removeDisplay');
        $('#editButton1').removeClass('removeDisplay');
        $('#editButton2').removeClass('removeDisplay');
        $('#editButton3').removeClass('removeDisplay');
        $('#editButton6').removeClass('removeDisplay');
        this.disableInputs();
    }
    disableInputs() {
        $('.onEditHide,.defaultKeywords').show();
        $('.showAble').hide();
        this.editMode = false;
    }
    uploadFile = async (e) => {

        e.preventDefault();
        this.showIndicator();
        if (e.target.files[0] && e.target.files[0].size < 20971520 && (e.target.files[0].type === 'image/jpeg' || e.target.files[0].type === 'image/x-ms-bmp' || e.target.files[0].type !== 'image/png' || e.target.files[0].type !== 'image/gif')) {
            this.setState({ profilePicture: window.URL.createObjectURL(e.target.files[0]) });
            let fileName = e.target.files[0].name;
            try {
                const uploadedFilename = (e.target.files[0])
                    ? (await s3Upload(e.target.files[0], this.props.auth.user.userName)).Location
                    : null;
                let that = this;
                if (uploadedFilename) {
                    this.setState({ 'fileName': fileName, 'fileLink': uploadedFilename });
                    let body = {
                        userName: this.state.userName,
                        profilePicture: uploadedFilename
                    };
                    apiClient.invokeApi({}, '/user', 'PUT', {}, body, "2").then(function (result) {
                        ClevertapReact.event("User's profile updated", { 'userName': that.props.auth.user.userName });
                        apiClient.invokeApi({ userName: that.state.userName }, '/user/{userName}', 'GET', {}, {})
                            .then(function (userReturned) {
                                localStorage.setItem('userObj', JSON.stringify(userReturned.data));
                                setCurrentUser(userReturned.data);
                            }).catch(function (result) {
                                //This is where you would put an error callback
                            });
                        that.setState({ showAlert: true, alertText: result.data, alertStyle: "success" });
                        that.disableInputs();
                        setTimeout(() => {
                            that.setState({ ...that.state, showAlert: false });
                        }, 2000);
                        that.hideIndicator();
                    }).catch(function (result) {
                        that.setState({ showAlert: true, alertText: result.response.data.errors[0].message, alertStyle: "danger" });
                        setTimeout(() => {
                            that.setState({ ...that.state, showAlert: false });
                        }, 2000);
                    });
                }
                else {
                    that.hideIndicator();
                    this.setState({ 'fileName': '', 'fileLink': '' });
                }
            }
            catch (err) {

                console.log(err);
                //    this.state.errors.fileName = err.message;
                setTimeout(() => {
                    this.setState({ ...this.state, showAlert: false });
                }, 5000);
            }
        }
        else {
            this.hideIndicator();
        }

    };

    refreshCache() {

    }

    onChange(event) {
        this.setState({ [event.target.id]: event.target.value });
    }
    handleAlertDismiss(e) {
        this.setState({ 'showAlert': false });
    }

    onSubmit() {
        this.showIndicator();
        let state = this.state;
        let body = {
            userName: state.userName,
            firstName: state.firstName,
            lastName: state.lastName,
            email: state.email,
            prefLanguage: state.langCode
        };
        let that = this;
        const { dispatch } = this.props;
        apiClient.invokeApi({}, '/user', 'PUT', {}, body, "2").then(function (result) {
            ClevertapReact.event("User's profile updated", { 'userName': that.props.auth.user.userName });
            apiClient.invokeApi({ userName: state.userName }, '/user/{userName}', 'GET', {}, {})
                .then(function (userReturned) {

                    if (state.email) {
                        let newUserPool = new CognitoUserPool({
                            UserPoolId: config.userPoolId,
                            ClientId: config.clientId
                        });
                        let cognitoUser = newUserPool.getCurrentUser();

                        let attributeList = [];
                        let attribute = {
                            Name: 'email',
                            Value: state.email
                        };
                        let cognitoAttribute = new CognitoUserAttribute(attribute);
                        attributeList.push(cognitoAttribute);
                        cognitoUser.getSession(function (err, session) {
                            if (session) {
                                cognitoUser.updateAttributes(attributeList, function (err, updatedResult) {
                                    if (err) {

                                    }
                                    else if (updatedResult) {
                                        let newUserPool2 = new CognitoUserPool({
                                            UserPoolId: config.userPoolId,
                                            ClientId: config.clientId
                                        });
                                        let cognitoUser2 = newUserPool2.getCurrentUser();
                                        cognitoUser2.getSession(function (err, session) {
                                            if (session) {
                                                cognitoUser2.getUserAttributes(function (err, result2) {
                                                    if (err) {

                                                    }
                                                    else {
                                                        let index = result2.findIndex(x => x.Name === 'email_verified');
                                                        if (index !== -1) {
                                                            if (result2[index].Value === "true") {
                                                                userReturned.data.emailVerified = true;
                                                            }
                                                            else {
                                                                userReturned.data.emailVerified = false;
                                                            }
                                                        }
                                                        else {
                                                            userReturned.data.emailVerified = false;
                                                        }
                                                    }
                                                });
                                            }
                                        });
                                        localStorage.setItem('userObj', JSON.stringify(userReturned.data));
                                        dispatch({
                                            type: 'SET_CURRENT_USER',
                                            user: userReturned.data
                                        });

                                        that.loaderSettings(result)


                                    }
                                    // console.log('call result: ' + result);
                                });
                            }
                        });
                    }
                    else {
                        let newUserPool2 = new CognitoUserPool({
                            UserPoolId: config.userPoolId,
                            ClientId: config.clientId
                        });
                        let cognitoUser2 = newUserPool2.getCurrentUser();
                        cognitoUser2.getSession(function (err, session) {
                            if (session) {
                                cognitoUser2.getUserAttributes(function (err, result2) {
                                    if (err) {

                                    }
                                    else {
                                        let index = result2.findIndex(x => x.Name === 'email_verified');
                                        if (index !== -1) {
                                            if (result2[index].Value === "true") {
                                                userReturned.data.emailVerified = true;
                                            }
                                            else {
                                                userReturned.data.emailVerified = false;
                                            }
                                        }
                                        else {
                                            userReturned.data.emailVerified = false;
                                        }
                                    }
                                });
                            }
                        });
                        localStorage.setItem('userObj', JSON.stringify(userReturned.data));
                        dispatch({
                            type: 'SET_CURRENT_USER',
                            user: userReturned.data
                        });

                        that.loaderSettings(result)

                    }
                }).catch(function (result) {
                    //This is where you would put an error callback
                });
            // that.setState({showAlert:true, alertText:result.data, alertStyle:"success"});
            // that.disableInputs();
            // setTimeout(()=>{
            //     that.setState({...that.state, showAlert: false});
            // }, 2000);
            // that.hideIndicator();

        }).catch(function (result) {
            that.setState({ showAlert: true, alertText: result.response ? result.response.data.errors[0].message : result.message, alertStyle: "danger" });
            setTimeout(() => {
                that.setState({ ...that.state, showAlert: false });
            }, 2000);
            that.hideIndicator();

        });
    }

    loaderSettings(result) {
        this.setState({ showAlert: true, alertText: result.data, alertStyle: "success" });
        this.disableInputs();
        this.cancelSubmit();
        setTimeout(() => {
            this.setState({ showAlert: false });
        }, 2000);
        this.hideIndicator();
    }

    submitPassword() {
        const { errors, isValid } = this.getValidationState();
        if (!isValid) {
            this.setState({ errors });
        }
        else {
            let that = this;
            that.setState({ errors });
            that.showIndicator();

            changePassword(that.state.oldPassword, that.state.newPassword, function (err, result) {

                if (result) {
                    let body = {
                        userName: that.props.auth.user.userName,
                        userPassword: that.state.newPassword
                    };
                    apiClient.invokeApi({}, '/user', 'PUT', {}, body, "2").then(function (result) {
                        ClevertapReact.event("User's password updated", { 'userName': that.props.auth.user.userName });
                        that.setState({
                            showAlert: true,
                            alertText: "Password changed successfully",
                            alertStyle: "success",
                            showChangePasswordDialogBox: false,
                            oldPassword: "",
                            newPassword: ""
                        });
                        setTimeout(() => {
                            that.setState({ ...that.state, showAlert: false });
                        }, 5000);
                    });
                } else {
                    that.setState({
                        showAlert: true,
                        alertText: err,
                        alertStyle: "danger",
                        showChangePasswordDialogBox: false,
                        oldPassword: "",
                        newPassword: ""
                    });
                    setTimeout(() => {
                        that.setState({ ...that.state, showAlert: false });
                    }, 5000);
                }
            });


            that.hideIndicator();

        }
    }

    showDialog(event) {

        if (event.target.id === 'changePassword') {
            this.setState({ showChangePasswordDialogBox: true });
        }
        else if (event.target.id === 'enrollmentCode') {
            this.setState({ showEnrollmentDialogBox: true });
        }
        else if (event.target.id === 'schoolCode') {
            this.setState({ showSchoolCodeDiaogBox: true });
        }

    }

    getValidationState() {
        let errors = {};
        if (this.state.newPassword.length < 8) {
            errors.newPassword = 'Your password must be at least 8 characters';
        }
        if (this.state.newPassword.search(/[a-z]/i) < 0) {
            errors.newPassword = 'Your password must contain at least one lower case letter';
        }
        if (this.state.newPassword.search(/[A-Z]/i) < 0) {
            errors.newPassword = 'Your password must contain at least one upper case letter';
        }
        // if (this.state.newPassword.search(/[0-9]/) < 0) {
        //     errors.newPassword ='Your password must contain at least one digit';
        // }
        // if (this.state.newPassword.search(/[!@#$%^&*]/) < 0) {
        //     errors.newPassword ='Your password must contain at least one special character';
        // }
        if (this.state.oldPassword.length < 8 ||
            this.state.oldPassword.search(/[a-z]/i) < 0 ||
            this.state.oldPassword.search(/[A-Z]/i) < 0)
        // this.state.oldPassword.search(/[0-9]/) < 0 ||
        // this.state.oldPassword.search(/[!@#$%^&*]/) < 0)
        {
            errors.oldPassword = 'Invalid password';
        }
        if (this.state.newPassword !== this.state.confirmPassword) {
            errors.newPassword = 'Password does not match';
            errors.confirmPassword = 'Password does not match';
        }
        if (Validator.isEmpty(this.state.oldPassword)) {
            errors.oldPassword = 'This field is required';
        }
        if (Validator.isEmpty(this.state.newPassword)) {
            errors.newPassword = 'This field is required';
        }
        if (Validator.isEmpty(this.state.confirmPassword)) {
            errors.confirmPassword = 'This field is required';
        }
        return {
            errors,
            isValid: isEmpty(errors)
        }
    }

    getChangePasswordDialog() {
        let errors = this.state.errors;
        let body =
            <div>
                <TextFieldGroup
                    type='password'
                    error={errors.oldPassword}
                    field="oldPassword"
                    id="oldPassword"
                    value={this.state.oldPassword}
                    placeholder="Enter Current Password"
                    onChange={this.onChange}
                />
                <TextFieldGroup
                    type='password'
                    error={errors.newPassword}
                    field="newPassword"
                    id="newPassword"
                    value={this.state.newPassword}
                    placeholder="Enter New Password"
                    onChange={this.onChange}
                />
                <TextFieldGroup
                    type='password'
                    error={errors.confirmPassword}
                    id="confirmPassword"
                    field="confirmPassword"
                    value={this.state.confirmPassword}
                    placeholder="Confirm New Password"
                    onChange={this.onChange}
                />
            </div>;
        return <Modal
            show={this.state.showChangePasswordDialogBox}
            onHide={() => {
                this.setState({ showChangePasswordDialogBox: false });
            }}
            container={this}
            aria-labelledby="contained-modal-title">
            <Modal.Header closeButton>
                <Modal.Title id="delete-activity-modal">Change Password</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                {body}
            </Modal.Body>
            <Modal.Footer>
                <Button onClick={() => {
                    this.setState({ showChangePasswordDialogBox: false });
                }}>Cancel</Button>
                <Button onClick={() => {
                    this.submitPassword();
                }} bsStyle='primary'>Submit</Button>
            </Modal.Footer>
        </Modal>;
    }

    getDialog() {

        return <Modal id="hint_modal"
            show={this.state.showDialog}
            onHide={() => {
                this.setState({ showDialog: false });
            }}
            container={this}
            aria-labelledby="contained-modal-title">
            <Modal.Header closeButton>
                <Modal.Title id="delete-activity-modal">{this.state.dialogTitle}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                {this.state.dialogMessage}
            </Modal.Body>
            <Modal.Footer>
                <Button onClick={() => {
                    this.setState({ showDialog: false });
                }} bsStyle='primary'>OK</Button>
            </Modal.Footer>
        </Modal>;
    }

    submitEnrollmentCode() {
        if (Validator.isEmpty(this.state.enrollmentCode)) {
            let errors = {};
            errors.enrollmentCode = 'This Field is required';
            this.setState(errors);
        }
        else {
            this.showIndicator();
            let that = this;

            let body = {
                userName: this.props.auth.user.userName,
                enrollmentCode: this.state.enrollmentCode,
                schoolID: this.props.auth.user.schoolID,
                firstName: this.props.auth.user.firstName,
                lastName: this.props.auth.user.lastName,
            };
            apiClient.invokeApi({}, '/user/enrollmentCode', 'PUT', {}, body, '2').then(function (result) {
                ClevertapReact.event("Student's enrollment", { 'userName': that.props.auth.user.userName });
                let body2 = {
                    userName: that.props.auth.user.userName,
                    enrollmentCode: that.state.enrollmentCode,
                    isNotRegistered: "true"
                };
                apiClient.invokeApi({}, '/assignment/student', 'POST', {}, body2, "5").then(function (result) {
                    apiClient.invokeApi({ userName: that.props.auth.user.userName }, '/user/{userName}', 'GET', {}, {})
                        .then(function (userReturned) {
                            localStorage.setItem('userObj', JSON.stringify(userReturned.data));
                            that.setState({ 'multiLingualLicense': userReturned.data.multiLingualLicense });
                            setCurrentUser(userReturned.data);
                        }).catch(function (result) {
                            //This is where you would put an error callback
                        });


                    if (result.data.assignmentCount > 0) {
                        let message = "Please refresh the page momentarily as they will appear on your Student Dashboard under Open Assignments.";
                        that.setState({ showDialog: true, dialogTitle: "You Have Open Assignments!", dialogMessage: message });


                        // that.setState({showAlert:true, alertText:result.data.message, alertStyle:"success", enrollmentCode:''});

                    }


                    that.hideIndicator();
                    that.setState({ showEnrollmentDialogBox: false });


                    setTimeout(() => {
                        that.setState({ ...that.state, showAlert: false });
                    }, 5000);
                    that.hideIndicator();


                }).catch(function (result) {

                    that.hideIndicator();
                    that.setState({ enrollmentCode: '', showAlert: true, alertText: result.response.data, alertStyle: "danger", showEnrollmentDialogBox: false });
                    setTimeout(() => {
                        that.setState({ ...that.state, showAlert: false });
                    }, 5000);
                });
            }).catch(function (result) {
                that.hideIndicator();
                that.setState({ enrollmentCode: '', showAlert: true, alertText: result.response.data, alertStyle: "danger", showEnrollmentDialogBox: false });
            });
        }
    }
    submitSchoolCode() {
        if (Validator.isEmpty(this.state.schoolCode)) {
            let errors = {};
            errors.schoolCode = 'This Field is required';
            this.setState(errors);
        }
        else {
            this.showIndicator();
            let that = this;
            let body = {
                userName: this.props.auth.user.userName,
                studentCode: this.state.schoolCode
            };
            apiClient.invokeApi({}, '/user/licensecode', 'PUT', {}, body, '2').then(function (result) {

                apiClient.invokeApi({ userName: that.state.userName }, '/user/{userName}', 'GET', {}, {})
                    .then(function (userReturned) {
                        localStorage.setItem('userObj', JSON.stringify(userReturned.data));
                        setCurrentUser(userReturned.data);
                    }).catch(function (result) {
                    });
                that.setState({ showAlert: true, alertText: result.data, alertStyle: "success", showSchoolCodeDiaogBox: false });
                setTimeout(() => {
                    that.setState({ ...that.state, showAlert: false });
                }, 5000);
                that.hideIndicator();
            }).catch(function (result) {

                that.hideIndicator();
                that.setState({ showAlert: true, alertText: result.response.data.errors[0].message, alertStyle: "danger", showSchoolCodeDiaogBox: false });
                setTimeout(() => {
                    that.setState({ ...that.state, showAlert: false });
                }, 5000);
            });
        }
    }
    getEnrollmentDialogBox() {
        let errors = this.state.errors;
        let body =
            <div>
                <TextFieldGroup
                    error={errors.enrollmentCode}
                    id="enrollmentCode"
                    field="enrollmentCode"
                    value={this.state.enrollmentCode}
                    placeholder="Enter Enrollment Code"
                    onChange={this.onChange}
                />
            </div>;
        return <Modal
            show={this.state.showEnrollmentDialogBox}
            onHide={() => {
                this.setState({ showEnrollmentDialogBox: false });
            }}
            container={this}
            aria-labelledby="contained-modal-title">
            <Modal.Header closeButton>
                <Modal.Title id="delete-activity-modal">Add Enrollment Code</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                {body}
            </Modal.Body>
            <Modal.Footer>
                <Button onClick={() => {
                    this.setState({ showEnrollmentDialogBox: false });
                }}>Cancel</Button>
                <Button onClick={() => {
                    this.submitEnrollmentCode();
                }} bsStyle='primary'>Add</Button>
            </Modal.Footer>
        </Modal>;
    }
    getSchoolCodeDialog() {
        let errors = this.state.errors;
        let body =
            <div>
                <TextFieldGroup
                    error={errors.schoolCode}
                    id="schoolCode"
                    field="schoolCode"
                    value={this.state.schoolCode}
                    placeholder="Enter School Code"
                    onChange={this.onChange}
                />
            </div>;
        return <Modal
            show={this.state.showSchoolCodeDiaogBox}
            onHide={() => {
                this.setState({ showSchoolCodeDiaogBox: false });
            }}
            container={this}
            aria-labelledby="contained-modal-title">
            <Modal.Header closeButton>
                <Modal.Title id="delete-activity-modal">School Code</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                {body}
            </Modal.Body>
            <Modal.Footer>
                <Button onClick={() => {
                    this.setState({ showSchoolCodeDiaogBox: false });
                }}>Cancel</Button>
                <Button onClick={() => {
                    this.submitSchoolCode();
                }} bsStyle='primary'>Submit</Button>
            </Modal.Footer>
        </Modal>;
    }

    render() {
        let state = this.state;
        return (
            <div>
                {this.getChangePasswordDialog()}
                {this.getEnrollmentDialogBox()}
                {this.getSchoolCodeDialog()}
                <span>
                <div className="searchCrtBar">
                <div className="container-fluid">
                <div className="row">
                <div className="col-lg-6 col-md-6 col-xs-8">
                <h3 style={{marginTop:"17px",fontWeight: "bold"}}>Student Profile</h3>
                </div>
                </div>
                </div>
                </div>
                </span>
                <div className="container-fluid profilePage grey-background">
                    <div className="wrapperProfileBox">
                        <div className="row">
                            <div className="col-md-3 col-sm-3 col-xs-5"> <img className="img-circle profilePic" src={state.profilePicture ? state.profilePicture : dummyImage} /> </div>
                            <div className="col-md-9  col-sm-9 col-xs-6">
                                <div className="chngPicBtn">Change Picture
                                    <input type="file" onChange={this.uploadFile} />
                                </div>
                            </div>
                        </div>
                        <div className="profileRowBox">
                            {!this.props.auth.user.federatedIdentity && <div className="row">
                                <div className="col-md-3 col-sm-3 col-xs-3">
                                    <p className="lblText">Login Name:</p>
                                </div>
                                <div className="col-md-9  col-sm-9 col-xs-9">
                                    <p className="labelTextValue clrGrey">{state.userName}</p>
                                </div>
                            </div>}
                        </div>
                        <div className="profileRowBox">
                            <div className="row">
                                <div className="col-md-3  col-sm-3 col-xs-3">
                                    <p className="lblText">Password:</p>
                                </div>
                                <div className="col-md-9   col-sm-9 col-xs-9">
                                    <a href="javascript:;" id="changePassword" onClick={this.showDialog}>Reset Password</a>
                                </div>
                            </div>
                        </div>
                        <div className="profileRowBox">
                            <div className="row">
                                <div className="col-md-3 col-sm-3 col-xs-3">
                                    <p className="lblText">First Name:</p>
                                </div>
                                <div className="col-md-8  col-sm-7 col-xs-9">
                                    <p className="labelTextValue clrGrey onEditHide">{state.firstName}</p>
                                    <div className="form-group showAble">
                                        <div className="posRel fieldBg selectWidth">
                                            <input type="text" className="form-control" value={state.firstName} id="firstName" onChange={this.onChange} />
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-1 col-sm-2 col-xs-1"><span id="editButton1" className="edtBtn" onClick={this.enableInputs} /></div>
                            </div>
                        </div>
                        <div className="profileRowBox">
                            <div className="row">
                                <div className="col-md-3 col-sm-3 col-xs-3">
                                    <p className="lblText">Last Name:</p>
                                </div>
                                <div className="col-md-8  col-sm-7 col-xs-9">
                                    <p className="labelTextValue clrGrey onEditHide">{state.lastName}</p>
                                    <div className="form-group showAble">
                                        <div className="posRel fieldBg selectWidth">
                                            <input type="text" className="form-control" value={state.lastName} id="lastName" onChange={this.onChange} />
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-1 col-sm-2 col-xs-1"><span id="editButton2" className="edtBtn  hidden-xs" onClick={this.enableInputs} /></div>
                            </div>
                        </div>
                        {this.state.multiLingualLicense && <div className="profileRowBox">
                            <div className="row">
                                <div className="col-md-3  col-sm-3 col-xs-3">
                                    <p className="lblText">Language:</p>
                                </div>
                                <div className="col-md-8 col-sm-7 col-xs-9 langStyle">
                                    <p className="labelTextValue clrGrey onEditHide">{state.isLanguage ? state.isLanguage : "English"}</p>
                                    <SelectFieldGroup
                                        placeholder={this.state.isLanguage}
                                        options={this.state.languages}
                                        show={true}
                                        onChange={this.onLanguageSelect}
                                    />
                                    <img className="ml-beta" src={betaIcon} />
                                </div>
                                <div className="col-md-1 col-sm-2 col-xs-1"><span id="editButton3" className="edtBtn  hidden-xs" onClick={this.enableInputs} /></div>
                            </div>
                        </div>}

                        <div className="profileRowBox">
                            <div className="row">
                                <div className="col-md-3  col-sm-3 col-xs-3">
                                    <p className="lblText">School:</p>
                                </div>
                                <div className="col-md-9   col-sm-9 col-xs-9">
                                    {state.school && <p className="labelTextValue clrGrey">{state.school}</p>}
                                    {!state.school && <a href="javascript:;" id="schoolCode" onClick={this.showDialog}>Add Universal Student Registration Code</a>}
                                </div>
                            </div>
                        </div>
                        <div className="profileRowBox">
                            <div className="row">
                                <div className="col-md-3  col-sm-3 col-xs-3">
                                    <p className="lblText">Email:</p>
                                </div>
                                <div className="col-md-8   col-sm-7 col-xs-9">
                                    <p className="labelTextValue clrGrey  onEditHide">{state.email ? state.email : 'email not found'}</p>
                                    <div className="form-group showAble">
                                        <div className="posRel fieldBg selectWidth">
                                            <input type="text" className="form-control" value={state.email ? state.email : 'email not found'} id="email" onChange={this.onChange} />
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-1 col-sm-2 col-xs-1"><span id="editButton6" className="edtBtn hidden-xs" onClick={this.enableInputs} /></div>
                            </div>
                        </div>
                        <div className="profileRowBox">
                            <div className="row">
                                <div className="col-md-3  col-sm-3 col-xs-3">
                                    <p className="lblText">Enrollment Codes:</p>
                                </div>
                                <div className="col-md-9   col-sm-9 col-xs-9">
                                    {this.state.allEnrollments}
                                    <div style={{ padding: '5px 0px' }}><a href="javascript:;" id="enrollmentCode" onClick={this.showDialog}>Add Enrollment Code</a></div>
                                </div>
                            </div>
                        </div>
                        <div className="profileRowBox">
                            <div className="row">
                                <div className="col-md-12  col-sm-12 col-xs-12 text-right">
                                    <button id="cancelButton" className="btn-cancel removeDisplay" onClick={this.cancelSubmit}>Cancel</button>
                                    <button id="submitButton" className="btn-saveBtn removeDisplay" onClick={this.onSubmit}>Submit</button>
                                </div>
                            </div>
                        </div>
                    </div>
                    <ProgressIndicator show={this.state.loading} />
                    {this.state.showAlert &&
                        <div className="row">
                            <div className="col-md-3 col-xs-3" />
                            <div className="col-md-6 col-xs-3">
                                <Alert className={classnames({ 'unsetHeight': this.state.unsetHeight })} bsStyle={this.state.alertStyle}
                                    onDismiss={this.handleAlertDismiss}>
                                    {this.state.alertText}
                                </Alert>
                            </div>
                            <div className="col-md-3 col-xs-3" />
                        </div>
                    }

                    {this.state.showDialog && this.getDialog()}
                </div>
            </div>
        );
    }
}


function mapStateToProps(state) {
    return { ...state };
}

export default connect(mapStateToProps)(StudentProfile);
